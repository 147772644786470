import React from "react"
import VideoLayout from "./videoLayout"

function Decorations() {
  return (
    <VideoLayout title="Decorations">
      <video width="390" height="220" controls muted>
        <source
          src=" https://res.cloudinary.com/diw3segy3/video/upload/v1607706423/videos/sets/1607633194398_m1peil.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1605646998/videos/sets/vid-42_vk9dad.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1600736701/videos/sets/1600736515300_wgzqr4.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091673/videos/sets/vid-1_fdy6os.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091678/videos/sets/vid-2_kwqyhd.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091693/videos/sets/vid-3_cgsnpp.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091678/videos/sets/vid-4_dhpwgx.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091746/videos/sets/vid-5_discwb.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091695/videos/sets/vid-6_ndmesk.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091676/videos/sets/vid-7_phna4q.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091678/videos/sets/vid-8_ij1vme.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091682/videos/sets/vid-9_nycqex.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091688/videos/sets/vid-10_esrts1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091695/videos/sets/vid-11_g84jxv.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091692/videos/sets/vid-12_wcjbr0.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091693/videos/sets/vid-13_h5kgwc.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091696/videos/sets/vid-14_xjdoke.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091708/videos/sets/vid-15_d3gq7m.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091704/videos/sets/vid-16_rebkgq.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091740/videos/sets/vid-17_qkf4uf.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091704/videos/sets/vid-18_axsh23.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091740/videos/sets/vid-19_po2n9l.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091711/videos/sets/vid-20_l17cf9.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091717/videos/sets/vid-21_enlhwh.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091722/videos/sets/vid-22_h8prjh.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091724/videos/sets/vid-23_dhdsyp.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091728/videos/sets/vid-24_rxo2qj.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091731/videos/sets/vid-25_yzozm9.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091736/videos/sets/vid-26_nyzu7x.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091748/videos/sets/vid-27_djlpok.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091741/videos/sets/vid-28_cw7xmn.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091749/videos/sets/vid-29_hwuu4x.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091750/videos/sets/vid-30_ecp7t0.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091760/videos/sets/vid-31_ojysxc.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091754/videos/sets/vid-32_rmytq9.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091769/videos/sets/vid-33_z8nias.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091758/videos/sets/vid-34_gokkdb.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091770/videos/sets/vid-35_uzgnt3.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091771/videos/sets/vid-36_d88g7q.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091765/videos/sets/vid-37_n9uuxx.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091762/videos/sets/vid-38_bjyxek.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091773/videos/sets/vid-39_oyjcmm.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091773/videos/sets/vid-40_rvqjog.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596091780/videos/sets/vid-41_rsljcr.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871273/videos/sets/12075_gymrmp.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871314/videos/sets/72301_e2xqu4.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871382/videos/sets/11441_n5gbex.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871386/videos/sets/2367_ganku9.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871403/videos/sets/2a484d005a7a77b5d2314885d80670_wiqvkn.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871413/videos/sets/69febeb5ba8b4f633cce84b9eb7ada_siryru.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871457/videos/sets/1190_gppklf.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871470/videos/sets/8ac37c1cfccfdf10bd1fd984167eb4_q3vpbe.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871481/videos/sets/a31e3eb9ea683010e1aed5e8b68e8a_amqjce.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871496/videos/sets/0e469e8d96b644d096dd5c01b1e39b_idrjuh.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871505/videos/sets/448406589eaa3791b38d9a2c0f7b15_giazxq.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871514/videos/sets/068c7a34ffe9a0fa87b016393264a4_xtw42a.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871558/videos/sets/16cbbe53b4f2c829840b0b42163d57_hqa62b.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871600/videos/sets/fb5e5646da7ed63830e8ddc2a194a1_iv13jm.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871625/videos/sets/11a6ef646d656c5e2a40ef53bea1ca_fcsh6e.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871680/videos/sets/12069_kqp6ya.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871715/videos/sets/001_WC-EditVideo_1_wbiulj.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871792/videos/sets/8ce8dce049ce1b8fc2e570682a673d_vpzomc.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871813/videos/sets/a1ca03d7eaab5a4b1592e31660e4a4_ocipbo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871822/videos/sets/8046ee7b6e40983ae6e2c053ca0ebb_umawgt.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871831/videos/sets/01b9d7496d6512891659673b65f62e_tzxltg.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871840/videos/sets/ff5fb64ceb2c639920491e88187e89_pvyo3s.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871849/videos/sets/be67e07c7df1f25c6832d698e9fc3e_dbacqt.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871877/videos/sets/IMG_3523_uvo91f.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871891/videos/sets/IMG_3517_j2c8lj.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871895/videos/sets/86f32845e8bb3464a6ddbfbf4988d9ed_ainbdr.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871904/videos/sets/63ba43e08cd06925c736cf11a85486_yhbyod.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871949/videos/sets/IMG_0816_uhvwpv.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </VideoLayout>
  )
}

export default Decorations
