import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Seo from "../../components/seo"
import Layout from "../../components/layout"

function VideoLayout(props) {
  return (
    <Layout>
      <Seo title="Videos" />
      <GalleryHeader />
      <GalleryMain>
        <h1>{props.title}</h1>
        <GalleryBackLink to="/videos">Go Back</GalleryBackLink>
        <GalleryGrid>{props.children}</GalleryGrid>
      </GalleryMain>
    </Layout>
  )
}

export default VideoLayout

const GalleryHeader = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(147, 181, 179, 0.1)
    ),
    url("https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086855/couples/couple-38v_xxjqbf.jpg");
  width: 100%;
  height: 35vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`

const GalleryBackLink = styled(Link)`
  margin: 0 auto;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease;

  &:hover {
    font-weight: bold;
  }
`

const GalleryMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  h1 {
    text-decoration: underline;
    margin-bottom: 3rem;
    text-align: center;
    font-family: "Noto Serif Display", serif;
  }
`

const GalleryGrid = styled.div`
  padding: 3rem 15vw;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 2rem;
  row-gap: 2rem;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  grid-auto-flow: row;

  @media only screen and (max-width: 1300px) {
    padding: 2rem;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: auto;
  }

  @media only screen and (max-width: 500px) {
    padding: 2rem 1rem;
  }

  video {
    width: 390px;
    height: 220px;
    background: black;

    max-width: 90vw;
    max-height: 67vw;
  }
`
